import React, {useState} from 'react'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import './PdfViewer.css'
import env from "react-dotenv";
import TicketContext from '../../context/TicketContext'


const PdfViewer = () => {

    const { activePdfViewer, setActivePdfViewer, ticket, setTicketDefault, typePdf } = useContext(TicketContext)

    const handleError = (evt) => {
        console.log(evt)
    }

    return (
        <div className={activePdfViewer ? 'pdfViewer activeModal' : 'pdfViewer'}>
            <div className='modalContainer'>
                <div className='buttonContainer'>
                    <div></div>
                    <h2>{ typePdf ==='comprobante'?'Comprobante':'Ticket'}</h2>
                    <FontAwesomeIcon
                        className='closeModalIcon'
                        onClick={() => {
                            document.body.style.overflowY = "visible"
                            setTicketDefault()
                            setActivePdfViewer(false)
                        }}
                        icon={faXmark} />
                </div>
                <div className='editarContainer'>
                    {typePdf==='comprobante' ?
                        (ticket.link? (
                            <embed src={`${env.API_URL}/files/${ticket.idTicket}/${ticket.link}`} style={{width:"100%",height:"480px"}} frameborder="no"/>
                        ): 'No se encontró archivo')
                        : (
                            ticket.idTicket &&
                            (<iframe src={`${env.API_URL}/files/ticket/${ticket.idTicket}/${ticket.idTicket}_ticket.pdf`} style={{width:"100%",height:"480px"}} frameborder="no" onError={handleError}/>)
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default PdfViewer