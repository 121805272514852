import React, { useState } from 'react'
import { toast } from "react-toastify"
import {
    Button, TextField, Select, MenuItem, FormControl, InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress
} from '@mui/material'

import { makeStyles, withStyles } from '@mui/styles'
import './PagoContainer.css'
import { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalStateContext'
import { useEffect } from 'react'
import TicketContext from '../../context/TicketContext'
import PropietarioContext from '../../context/PropietarioContext'

const CssTextField = withStyles({
    root: {
        '&': {
            margin: '10px 0 10px !important'
        },
        '& p': {
            textAlign: 'left',
            fontSize: '12px',
            color: '#F94700 !important'
        },
        '& label': {
            fontSize: '16px'
        },
        '& label.Mui-focused': {
            color: '#F94700 !important',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #000 !important'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #F94700 !important',
        },
        '& .MuiSelect-select': {
            paddingLeft: '0 !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black'
            },
            '&:hover fieldset': {
                borderColor: 'black'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#F94700 !important'
            },
        },
    },
})(TextField);


const CssFormControl = withStyles({
    root: {
        '&': {
            margin: '20px 0 !important'
        },
        '& p': {
            textAlign: 'left',
            fontSize: '12px',
            color: '#ff5252 !important'
        },
        '& label': {
            fontSize: '16px'
        },
        '& label.Mui-focused': {
            color: '#ff5252 !important',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #000 !important'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #ff5252 !important',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black'
            },
            '&:hover fieldset': {
                borderColor: 'black'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ff5252 !important'
            },
        },
    },
})(FormControl);

const useStyles = makeStyles({
    button: {
        backgroundColor: '#f94700 !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#fff !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important',
        float: 'right'
    },
    button2: {
        backgroundColor: '#fff !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#f94700 !important',
        border: '1px solid #f94700 !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important',
        float: 'right'
    },
    button3: {
        backgroundColor: '#fff !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#f94700 !important',
        border: '1px solid #f94700 !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important',
        display: 'none !important'
    },
    th: {
        fontWeight: 'bolder !important',
        textAlign: 'center !important'
    },
    td: {
        textAlign: 'center !important'
    }
})


const ITEM_HEIGHT = 56;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PagoContainer = () => {

    const classes = useStyles()

    const { listarPropietarioConTickets, propietariosList, getTicketsPropietario, propietarioFilter, setPropietarioFilter, detalleTickets, setDetalleTickets, guardarPago, montoForm, setMontoForm, tipoForm, setTipoForm, bancoForm, setBancoForm, setCalcularAction } = useContext(TicketContext)
    const { buscarPropietario } = useContext(PropietarioContext);

    const { isLoading  } = useContext(GlobalContext)

    const [propietarioFilterAction, setPropietarioFilterAction] = useState(false)

    const handleChange = (evt) => {
        const { name, value } = evt.target

        if (name === 'monto') {
            setMontoForm(value)
        } else if (name === 'tipo') {
            setTipoForm(value)
        } else if (name === 'banco') {
            setBancoForm(value)
        } else if (name === 'propietarioFilter') {
            setPropietarioFilter(value)
            setPropietarioFilterAction(true)
        }
    }

    const calcularSaldoPendiente = () => {
        let suma = 0;
        detalleTickets.forEach((item) => suma += item.monto*1)
        return suma.toFixed(2);
    }

    const calcularPago = () => {
        let suma = 0;
        detalleTickets.forEach((item) => suma += item.pago*1)
        return suma.toFixed(2);
    }

    const calcularSaldoFinal = () => {
        let suma = 0;
        detalleTickets.forEach((item) => suma += (item.monto*1 - item.pago*1))
        return suma.toFixed(2);
    }

    const distribuirPago = () => {
        let montoAsignado = 0;
        let detalleTicketsAux = [...detalleTickets];
        let nuevoMonto = 0;
        detalleTicketsAux.forEach((item) => {
            nuevoMonto = montoForm - montoAsignado;
            nuevoMonto = nuevoMonto.toFixed(2);
            if(item.monto > nuevoMonto *1) {
                item.pago = nuevoMonto;
                montoAsignado += nuevoMonto*1; 
            } else {
                item.pago = item.monto;
                montoAsignado += item.monto*1; 
            }
        })
        setDetalleTickets(detalleTicketsAux);
        setCalcularAction(true);
        toast("Calculado")
    }

    useEffect(() => {
        listarPropietarioConTickets();
        buscarPropietario();
    }, [])


    return (
        <div className='administrar-container__item pagoContainer'>
            {
                isLoading && (
                    <div className='progressContainer'>
                        <CircularProgress color="success" style={{ width: '40px', height: '40px' }} /> 
                    </div>
                )
            }
            <div className='menuArea'>
                <div className='menuHeader'>
                    <h2>Pagos</h2>
                </div>
                {propietariosList.length > 0 ? (
                    <div>
                        <div className={"menuInputs"}>
                            <CssFormControl fullWidth
                                style={{ width: '100%' }}>
                                <InputLabel id="tipo-cliente-label" size="small">Cliente</InputLabel>
                                <Select
                                    label="Cliente"
                                    variant="outlined"
                                    name='propietarioFilter'
                                    className={`customSelect ${classes.root}`}
                                    value={propietarioFilter}
                                    onChange={handleChange}
                                    size="small"
                                    MenuProps={MenuProps}
                                >
                                    {
                                        propietariosList.map((item) => (
                                            <MenuItem 
                                            key={item.idPropietario}
                                            value={item.idPropietario}>
                                                {item.nombres}
                                            </MenuItem>
                                        ))

                                    }
                                </Select>
                            </CssFormControl>
                            <div>
                                <Button
                                    className={classes.button2}
                                    onClick={getTicketsPropietario}
                                    disabled={!propietarioFilterAction}
                                >Buscar</Button>
                            </div>
                        </div>
                        <hr />
                        <div className={"menuInputs"}>
                                <CssTextField fullWidth
                                    label="Monto del Pago"
                                    variant="outlined"
                                    name="monto"
                                    size="small"
                                    value={montoForm}
                                    type="number"
                                    InputProps={{ inputProps: { min: 0, step: 0.1 } }}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                    disabled={detalleTickets.length == 0}
                                />
                                <CssTextField fullWidth
                                    label="Tipo"
                                    variant="outlined"
                                    name="tipo"
                                    size="small"
                                    value={tipoForm}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                    disabled={detalleTickets.length == 0}
                                />
                                <CssTextField fullWidth
                                    label="Banco"
                                    variant="outlined"
                                    name="banco"
                                    size="small"
                                    value={bancoForm}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                    disabled={detalleTickets.length == 0}
                                />
                            <div>
                                <Button
                                    className={classes.button2}
                                    disabled={detalleTickets.length == 0}
                                    onClick={distribuirPago}
                                >Calcular</Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <h1>Cargando...</h1>
                )}

                <div className='menuContent'>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.th}>Ticket</TableCell>
                                    <TableCell className={classes.th}>Mes</TableCell>
                                    <TableCell className={classes.th}>Nro Comprobante</TableCell>
                                    <TableCell className={classes.th}>Saldo por pagar</TableCell>
                                    <TableCell className={classes.th}>Pago</TableCell>
                                    <TableCell className={classes.th}>Saldo Final</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {detalleTickets.map((row) => (
                                    <TableRow
                                        key={row.idTicket}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell className={classes.td}>{row.label}</TableCell>
                                        <TableCell className={classes.td}>{row.periodo}</TableCell>
                                        <TableCell className={classes.td}>{row.numero}</TableCell>
                                        <TableCell className={classes.td}>{row.monto}</TableCell>
                                        <TableCell className={classes.td}>{row.pago}</TableCell>
                                        <TableCell className={classes.td}>{(row.monto - row.pago).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                                {
                                    detalleTickets.length > 0 ? (
                                        <TableRow
                                            key='total'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell className={classes.th}>Totales</TableCell>
                                            <TableCell className={classes.th}></TableCell>
                                            <TableCell className={classes.th}></TableCell>
                                            <TableCell className={classes.th}>{calcularSaldoPendiente()}</TableCell>
                                            <TableCell className={classes.th}>{calcularPago()}</TableCell>
                                            <TableCell className={classes.th}>{calcularSaldoFinal()}</TableCell>
                                        </TableRow>
                                    ): ''
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                            <div>
                                <Button
                                    className={classes.button3}
                                    disabled={detalleTickets.length == 0}
                                >Ver pagos</Button>
                                <Button
                                    className={classes.button}
                                    disabled={detalleTickets.length == 0}
                                    onClick={guardarPago}
                                >Guardar</Button>
                            </div>
                </div>
            </div>
        </div>
    )
}

export default PagoContainer