import React from 'react'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import './EditarModal.css'
import {
    Button, TextField, Select, MenuItem, FormControl, InputLabel
} from '@mui/material'
import FileUploader from '../FileUploader/FileUploader'

import { makeStyles, withStyles } from '@mui/styles'
import TicketContext from '../../context/TicketContext'

const CssTextField = withStyles({
    root: {
        '&': {
            margin: '10px 0 10px !important'
        },
        '& p': {
            textAlign: 'left',
            fontSize: '12px',
            color: '#F94700 !important'
        },
        '& label': {
            fontSize: '16px'
        },
        '& label.Mui-focused': {
            color: '#F94700 !important',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #000 !important'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #F94700 !important',
        },
        '& .MuiSelect-select': {
            paddingLeft: '0 !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black'
            },
            '&:hover fieldset': {
                borderColor: 'black'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#F94700 !important'
            },
        },
    },
})(TextField);

const CssFormControl = withStyles({
    root: {
        '&': {
            margin: '10px 0 10px !important'
        },
        '& p': {
            textAlign: 'left',
            fontSize: '12px',
            color: '#ff5252 !important'
        },
        '& label': {
            fontSize: '16px'
        },
        '& label.Mui-focused': {
            color: '#ff5252 !important',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #000 !important'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #ff5252 !important',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black'
            },
            '&:hover fieldset': {
                borderColor: 'black'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ff5252 !important'
            },
        },
    },
})(FormControl);

const useStyles = makeStyles({
    button: {
        backgroundColor: '#f94700 !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#fff !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important'
    },
    buttonUpload: {
        color: '#f94700 !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        backgroundColor: '#fff !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important'
    },
    th: {
        fontWeight: 'bolder !important',
        textAlign: 'center !important'
    }
})


const EditarModal = () => {

    const classes = useStyles()
    const { activeEditarModal, setActiveEditarModal, ticket, setTicket, updateTicket, setTicketDefault, selectedFile, setSelectedFile } = useContext(TicketContext)

    const handleChange = (evt) => {
        const { name, value } = evt.target
        let auxTicket = Object.assign({},ticket);
        if (name === 'tipoComprobante') {
            auxTicket.tipoComprobante = value
        } else if (name === 'tipoPago') {
            auxTicket.tipoPago = value
        } else if (name === 'numero') {
            auxTicket.numero = value
        } else if (name === 'niubiz') {
            auxTicket.niubiz = value
        } else if (name === 'monto') {
            auxTicket.monto = value
        }
        setTicket(auxTicket);
    }

    return (
        <div className={activeEditarModal ? 'editarModal activeModal' : 'editarModal'}>
            <div className='modalContainer'>
                <div className='buttonContainer'>
                    <div></div>
                    <h2>Editar Ticket</h2>
                    <FontAwesomeIcon
                        className='closeModalIcon'
                        onClick={() => {
                            document.body.style.overflowY = "visible"
                            setActiveEditarModal(false)
                            setTicketDefault()
                            setSelectedFile(null)
                        }}
                        icon={faXmark} />
                </div>
                <div className='editarContainer'>
                    <CssTextField fullWidth
                        label="Id Ticket"
                        variant="outlined"
                        name="id"
                        size="small"
                        value={ticket.label}
                        disabled
                    />
                    <CssTextField fullWidth
                        label="Periodo"
                        variant="outlined"
                        name="periodo"
                        size="small"
                        value={ticket.periodo}
                        disabled
                    />
                    <CssTextField fullWidth
                        label="Propietario"
                        variant="outlined"
                        name="propietario"
                        size="small"
                        value={ticket.propietario}
                        disabled
                    />
                    <CssFormControl fullWidth
                        style={{ marginBottom: '16px !important', width: '100%' }}>
                        <InputLabel id="tipo-comprobante-label" size="small">Tipo de pago</InputLabel>
                        <Select
                            label="Tipo de pago"
                            variant="outlined"
                            name='tipoPago'
                            className={`customSelect ${classes.root}`}
                            value={ticket.tipoPago}
                            onChange={handleChange}
                            size="small"
                        >
                            <MenuItem value="Transferencia">Transferencia</MenuItem>
                            <MenuItem value="Niubiz">Niubiz</MenuItem>
                        </Select>
                    </CssFormControl>
                    <CssTextField fullWidth
                        label="Monto"
                        variant="outlined"
                        name="monto"
                        size="small"
                        value={ticket.monto}
                        type="number"
                        onChange={handleChange}
                        onBlur={handleChange}
                    />
                    <CssFormControl fullWidth
                        style={{ marginBottom: '16px !important', width: '100%' }}>
                        <InputLabel id="tipo-comprobante-label" size="small">Tipo de comprobante</InputLabel>
                        <Select
                            label="Tipo de comprobante"
                            variant="outlined"
                            name='tipoComprobante'
                            className={`customSelect ${classes.root}`}
                            value={ticket.tipoComprobante}
                            onChange={handleChange}
                            size="small"
                        >
                        <MenuItem value="Factura">Factura</MenuItem>
                        <MenuItem value="Boleta">Boleta</MenuItem>
                        </Select>
                    </CssFormControl>
                    <CssTextField fullWidth
                        label="Numero de comprobante"
                        variant="outlined"
                        name="numero"
                        size="small"
                        value={ticket.numero}
                        onChange={handleChange}
                        onBlur={handleChange}
                    />
                    <CssTextField fullWidth
                        label="T. Niubiz"
                        variant="outlined"
                        name="niubiz"
                        size="small"
                        value={ticket.niubiz}
                        onChange={handleChange}
                        onBlur={handleChange}
                    />
                     <FileUploader
                        onFileSelect={(file) => setSelectedFile(file)}
                        fileSelected={selectedFile}
                    />
                    <div className='menuFooter'>
                        <Button
                            className={classes.button}
                            onClick={updateTicket}
                        >Actualizar</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditarModal