import { Checkbox } from '@mui/material'
import React from 'react'
import { useContext } from 'react'
import './ArticuloCarrito.css'
import TicketContext from '../../context/TicketContext'

const ArticuloCarrito = ({ articulo }) => {


    const { ids, setIds } = useContext(TicketContext)

    return (
        <div className='articuloCarrito'>
            <div className='articleOptions'>
                <Checkbox
                    sx={{
                        '&.Mui-checked': {
                            color: '#F94700',
                        },
                    }}
                    onClick={(e) => {
                        if (e.target.checked) {
                            setIds([...ids, articulo.idArticulo])
                        } else {
                            setIds([...(ids.filter(el => el !== articulo.idArticulo))])
                        }
                    }}
                />
            </div>
            <div className='articleImage'>
                <img src={articulo.ImagenRecogida !== null
                    ? `https://storange-images.s3.amazonaws.com/appsheet/data/StorangeGestionLogistica_5361150/${articulo.ImagenRecogida}`
                    : 'https://pad.minem.gob.pe/Proyecta_CMS/Recursos/ProyectoImg/SinImagen.png'} alt={articulo.titulo} />
            </div>
            
            <div className='articleInfo'>
                <div>
                    <p className="info"><strong>Título:</strong> {articulo.Titulo}</p>
                    <p className="info"><strong>Descripción:</strong> {articulo.Descripcion}</p>
                    <p className="info"><strong>QR:</strong> {articulo.QRCode}</p>
                    <p className="info"><strong>Facturado hasta:</strong> {articulo.ultimaFacturacion && articulo.ultimaFacturacion.slice(0,10)}</p>
                    <p className="info"><strong>Volumen:</strong> {articulo.Volumen} m3</p>
                </div>

            </div>
        </div>
    )
}

export default ArticuloCarrito