import React from 'react'
import AdministrarContainer from '../components/AdministrarContainer/AdministrarContainer'
import { PropietarioProvider } from '../context/PropietarioContext'

const AdministrarView = () => {
  return (
    <div className='AdministrarView'>
      <PropietarioProvider>
        <AdministrarContainer/>
      </PropietarioProvider>
    </div>
  )
}

export default AdministrarView