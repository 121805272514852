import React, { createContext, useContext, useState } from 'react'
import env from "react-dotenv";
import { GlobalContext } from "./GlobalStateContext";

const PropietarioContext = createContext('');

const PropietarioProvider = ({ children, props }) => {
  const { accessToken } = useContext(GlobalContext)

  const [propietario, setPropietario] = useState({})
  const [activeDireccionModal, setActiveDireccionModal] = useState(false)

  const [name, setName] = useState('')
  const [nameAction, setNameAction] = useState(false)

  const [apellido, setApellido] = useState('')
  const [apellidoAction, setApellidoAction] = useState(false)

  const [email, setEmail] = useState('')
  const [emailAction, setEmailAction] = useState(false)

  const [phone, setPhone] = useState('')
  const [phoneAction, setPhoneAction] = useState(false)

  const [pass, setPass] = useState('')

  const [newPass, setNewPass] = useState('')
  const [newPassAction, setNewPassAction] = useState(false)

  const [newPassConfirm, setNewPassConfirm] = useState('')

  const handleChange = (evt) => {
    const { name, value } = evt.target

    if (name === 'name') {
      setName(value)
      setNameAction(true)
    } else if (name === 'apellido') {
      setApellido(value)
      setApellidoAction(true)
    } else if (name === 'email') {
      setEmail(value)
      setEmailAction(true)
    } else if (name === 'phone') {
      setPhone(value)
      setPhoneAction(true)
    } else if (name === 'pass') {
      setPass(value)
    } else if (name === 'newPass') {
      setNewPass(value)
      setNewPassAction(true)
    } else {
      setNewPassConfirm(value)
    }
  }
  
  const buscarPropietario = () => {
    fetch(env.API_URL+"/user/"+localStorage.getItem('trustedUser'), {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setPropietario(res)
      })
  }

  const object = {
    buscarPropietario,
    handleChange,
    propietario,
    name,
    nameAction,
    apellido,
    apellidoAction,
    email,
    emailAction,
    phone,
    phoneAction,
    pass,
    newPass,
    newPassAction,
    newPassConfirm,
    activeDireccionModal,
    setActiveDireccionModal,
  };

  return (
      <PropietarioContext.Provider value={object}>{children}</PropietarioContext.Provider>
  );
};

export { PropietarioProvider };
export default PropietarioContext;
