import React from 'react'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalStateContext'
import './DetalleArticuloModal.css'
import {
    Button, TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material'

import { makeStyles, withStyles } from '@mui/styles'
import TicketContext from '../../context/TicketContext'

const CssTextField = withStyles({
    root: {
        '&': {
            margin: '10px 0 10px !important'
        },
        '& p': {
            textAlign: 'left',
            fontSize: '12px',
            color: '#F94700 !important'
        },
        '& label': {
            fontSize: '16px'
        },
        '& label.Mui-focused': {
            color: '#F94700 !important',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #000 !important'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #F94700 !important',
        },
        '& .MuiSelect-select': {
            paddingLeft: '0 !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black'
            },
            '&:hover fieldset': {
                borderColor: 'black'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#F94700 !important'
            },
            '&.Mui-disabled': {
                color: 'red !important'
            }
        },
    },
})(TextField);

const useStyles = makeStyles({
    button: {
        backgroundColor: '#f94700 !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#fff !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important'
    },
    th: {
        fontWeight: 'bolder !important',
        textAlign: 'center !important'
    },
    td: {
        textAlign: 'center !important'
    }
})


const DetalleArticuloModal = () => {

    const classes = useStyles()
    const { activeDetalleArticuloModal, setActiveDetalleArticuloModal, ticket, detalle, detalleTicketArticulos } = useContext(TicketContext)

    return (
        <div className={activeDetalleArticuloModal ? 'detalleArticuloModal activeModal' : 'detalleArticuloModal'}>
            <div className='modalContainer'>
                <div className='buttonContainer'>
                    <FontAwesomeIcon
                        className='closeModalIcon'
                        onClick={() => {
                            document.body.style.overflowY = "visible"
                            setActiveDetalleArticuloModal(false)
                        }}
                        icon={faArrowLeft} />
                    <h2>Listado de artículos del Ticket</h2>
                    <div></div>
                </div>
                <div className='detalleContainer'>
                    <CssTextField fullWidth
                        label="Id Ticket"
                        variant="outlined"
                        name="id"
                        size="small"
                        value={ticket.label}
                        disabled
                    />
                    <CssTextField fullWidth
                        label="Periodo"
                        variant="outlined"
                        name="periodo"
                        size="small"
                        value={ticket.periodo}
                        disabled
                    />
                    <CssTextField fullWidth
                        label="Propietario"
                        variant="outlined"
                        name="propietario"
                        size="small"
                        value={ticket.propietario}
                        disabled
                    />
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 750 }} aria-label="simple table">
                                <TableBody>
                                    {detalleTicketArticulos[0] && detalle.filter(item => item.idDetalleTicket === detalleTicketArticulos[0].idDetalleTicket).map((row) => (
                                        <TableRow
                                            key={row.idDetalleTicket}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{row.concepto}</TableCell>
                                            <TableCell  className={classes.td}>{row.monto} m<sup>3</sup> </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow
                                            key={'unique'+ticket.idTicket}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell className={classes.th}></TableCell>
                                        <TableCell  className={classes.td}></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.th}>#</TableCell>
                                        <TableCell className={classes.th}>Nombre</TableCell>
                                        <TableCell className={classes.th}>QR Code</TableCell>
                                        <TableCell className={classes.th}>Volumen</TableCell>
                                        <TableCell className={classes.th}>Dias facturados</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {detalleTicketArticulos.map((row, index) => (
                                        <TableRow
                                            key={row.idDetalleTicketArticulo}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{++index}</TableCell>
                                            <TableCell>{row.Descripcion || row.Titulo}</TableCell>
                                            <TableCell>{row.QRCode}</TableCell>
                                            <TableCell  className={classes.td}>{row.Volumen} m<sup>3</sup></TableCell>
                                            <TableCell>{row.diasFacturados}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetalleArticuloModal