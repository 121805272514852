import React from 'react'
import { Outlet } from 'react-router-dom'
import Loader from './Loader/Loader'
import Navbar from './Navbar/Navbar'
import SecondaryNavbar from './SecondaryNavbar/SecondaryNavbar'
import EditarModal from './EditarModal/EditarModal'
import DetalleModal from './DetalleModal/DetalleModal'
import DetalleArticuloModal from './DetalleArticuloModal/DetalleArticuloModal'
import PdfViewer from './PdfViewer/PdfViewer'
import { PropietarioProvider } from '../context/PropietarioContext'
import { TicketProvider } from '../context/TicketContext'

const Layout = () => {

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <PropietarioProvider>
        <TicketProvider>
          <Navbar/>
          <Loader/>
          <Outlet/>
          <EditarModal/>
          <DetalleModal/>
          <DetalleArticuloModal/>
          <PdfViewer/>
          <SecondaryNavbar/>
        </TicketProvider>
      </PropietarioProvider>
    </div>
  )
}

export default Layout