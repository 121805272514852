import React from 'react'
import PerfilContainer from '../components/PerfilContainer/PerfilContainer'
import { PropietarioProvider } from '../context/PropietarioContext'

const PerfilView = () => {
  return (
    <div className='perfilView'>
      <PropietarioProvider>
        <PerfilContainer/>
      </PropietarioProvider>
    </div>
  )
}

export default PerfilView