import React, {useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import {
    Button
} from '@mui/material'
import './FileUploader.css'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    buttonUpload: {
        color: '#f94700 !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        backgroundColor: '#fff !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important'
    }
})


const FileUploader = ({onFileSelect, fileSelected}) => {
    const fileInput = useRef(null)

    const classes = useStyles()
    const handleFileInput = (e) => {
        // handle validations
        onFileSelect(e.target.files[0])
    }

    return (
        <div className="file-uploader">
            <div className="info">
                { fileSelected ? (
                    <div className="item-info">
                        {fileSelected.name}
                    </div>
                ): ''}
            </div>
            <Button variant="contained" component="label" className= {classes.buttonUpload} onClick={e => fileInput.current && fileInput.current.click()}>
                <span>Subir comprobante </span>
                <FontAwesomeIcon className='menuIcon' icon={faUpload} />
                <input id="file" name='file' hidden accept="application/*" type="file"  onChange={handleFileInput}/>
            </Button>
        </div>
    )
}

export default FileUploader