import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useContext, useEffect } from 'react'
import { Button, TextField } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import './PerfilContainer.css'
import { ToastContainer } from 'react-toastify'
import PropietarioContext from '../../context/PropietarioContext'

const CssTextField = withStyles({
  root: {
    '& p': {
      textAlign: 'left',
      fontSize: '12px',
      color: '#F94700 !important'
    },
    '& label': {
      fontSize: '16px'
    },
    '& label.Mui-focused': {
      color: '#F94700 !important',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #000 !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: '1px solid #F94700 !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black'
      },
      '&:hover fieldset': {
        borderColor: 'black'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F94700 !important'
      },
    },
  },
})(TextField);

const useStyles = makeStyles({
  button: {
    backgroundColor: '#f94700 !important',
    textTransform: 'none !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    width: '200px !important',
    margin: '20px 0 !important',
    height: 'fit-content !important'
  },
  buttonDireccion: {
    backgroundColor: '#f94700 !important',
    textTransform: 'none !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    width: '100% !important',
    margin: '20px 0 !important',
    height: 'fit-content !important'
  }
})

const regex = /^(?=\w*\d)(?=\w*[a-z])(?=\w*[a-z])\S{8,16}$/

const PerfilContainer = () => {

  const classes = useStyles()

  const { buscarPropietario, propietario, handleChange, name, nameAction, apellido, apellidoAction, email, emailAction, phone, phoneAction, pass, newPass, newPassAction, newPassConfirm } = useContext(PropietarioContext);
  const [section, setSection] = useState(1)

  useEffect(() => {
    buscarPropietario()
  }, [])


  return (
    <div className='perfilContainer'>
      <div className='menuPerfil'>
        <p>Configuración de la cuenta</p>
        <div className={section === 1 ? 'menuItem menuItemActive' : 'menuItem'} onClick={() => setSection(1)}>
          <FontAwesomeIcon className='menuIcon' icon={faUser} />
          Información personal privada
        </div>
      </div>
      {section === 1 ? (
        <div className='menuInfo'>
          <div className='menuArea'>
            <div className='menuHeader'>
              <h2>Información Personal Privada</h2>
            </div>
            {propietario !== null ? (
              <div className='menuInputs'>
                <CssTextField
                  label="Nombres"
                  variant="outlined"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleChange}
                  error={name === '' && nameAction}
                  helperText={name === '' && nameAction ? 'Nombre requerido' : ' '}
                  value={propietario.nombre && !nameAction ? propietario.nombre : name}
                  style={{ marginBottom: '16px' }}
                />
                <CssTextField
                  label="Apellidos"
                  variant="outlined"
                  name="apellido"
                  onChange={handleChange}
                  onBlur={handleChange}
                  error={apellido === '' && apellidoAction}
                  helperText={apellido === '' && apellidoAction ? 'Apellidos requeridos' : ' '}
                  value={propietario.apellido && !apellidoAction ? propietario.apellido : apellido}
                  style={{ marginBottom: '16px' }}
                />
                <CssTextField
                  label="Email"
                  variant="outlined"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleChange}
                  error={email === '' && emailAction}
                  helperText={email === '' && emailAction ? 'Email requerido' : ' '}
                  value={propietario.email && !emailAction ? propietario.email : email}
                  style={{ marginBottom: '16px' }}
                />
                <CssTextField
                  label="Teléfono"
                  variant="outlined"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleChange}
                  error={phone === '' && phoneAction}
                  helperText={phone === '' && phoneAction ? 'Teléfono requerido' : ' '}
                  value={propietario.telefono && !phoneAction ? propietario.telefono : phone}
                  style={{ marginBottom: '16px' }}
                />
              </div>
            ) : (
              <h1>Cargando...</h1>
            )}
            <div className='menuFooter'>
              <Button
                className={classes.button}
                onClick={() => console.log("Save")}
              >Guardar</Button>
            </div>
          </div>
          <div className='menuArea'>
            <div className='menuHeader'>
              <h2>Cambiar Contraseña</h2>
            </div>
            <div className='menuInputs'>
              <CssTextField
                type={'password'}
                label="Contraseña actual"
                variant="outlined"
                name="pass"
                onChange={handleChange}
                onBlur={handleChange}
                helperText={' '}
                value={pass}
                style={{ marginBottom: '16px' }}
              />
              <CssTextField
                type={'password'}
                label="Nueva contraseña"
                variant="outlined"
                name="newPass"
                onChange={handleChange}
                onBlur={handleChange}
                error={regex.test(newPass) === false && newPassAction}
                helperText={regex.test(newPass) === false && newPassAction ? 'De 8 a 16 carácteres entre números y letras' : ' '}
                value={newPass}
                style={{ marginBottom: '16px' }}
              />
              <CssTextField
                type={'password'}
                label="Confirmar contraseña"
                variant="outlined"
                name="newPassConfirm"
                onChange={handleChange}
                onBlur={handleChange}
                error={newPassConfirm !== newPass}
                helperText={newPassConfirm !== newPass ? 'Las contraseñas no coinciden' : ' '}
                value={newPassConfirm}
                style={{ marginBottom: '16px' }}
              />
            </div>
            <div className='menuFooter'>
              <Button
                onClick={() => console.log("Verify")}
                className={classes.button}>Guardar</Button>
            </div>
          </div>
        </div>
      ) : (
        <div className='menuDireccion'>

        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar
        theme='light'
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </div>
  )
}

export default PerfilContainer