import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrashCan, faList, faEye, faArrowLeft, faReceipt, faRotate } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import {
    Button, TextField, Select, MenuItem, FormControl, InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Chip,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from '@mui/material'
import ArticuloCarrito from '../ArticuloCarrito/ArticuloCarrito'

import dayjs from 'dayjs';
import { makeStyles, withStyles } from '@mui/styles'
import './TicketContainer.css'
import { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalStateContext'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TicketContext from '../../context/TicketContext'
import PropietarioContext from '../../context/PropietarioContext'

const CssTextField = withStyles({
    root: {
        '&': {
            margin: '10px 0 10px !important'
        },
        '& p': {
            textAlign: 'left',
            fontSize: '12px',
            color: '#F94700 !important'
        },
        '& label': {
            fontSize: '16px'
        },
        '& label.Mui-focused': {
            color: '#F94700 !important',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #000 !important'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #F94700 !important',
        },
        '& .MuiSelect-select': {
            paddingLeft: '0 !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black'
            },
            '&:hover fieldset': {
                borderColor: 'black'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#F94700 !important'
            },
        },
    },
})(TextField);


const CssFormControl = withStyles({
    root: {
        '&': {
            margin: '10px 0 10px !important'
        },
        '& p': {
            textAlign: 'left',
            fontSize: '12px',
            color: '#ff5252 !important'
        },
        '& label': {
            fontSize: '16px'
        },
        '& label.Mui-focused': {
            color: '#ff5252 !important',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #000 !important'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #ff5252 !important',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black'
            },
            '&:hover fieldset': {
                borderColor: 'black'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ff5252 !important'
            },
        },
    },
})(FormControl);

const useStyles = makeStyles({
    button: {
        backgroundColor: '#f94700 !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#fff !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important'
    },
    button2: {
        backgroundColor: '#f94700 !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#fff !important',
        border: '1px solid #f94700 !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important',
        float: 'right'
    },
    button3: {
        backgroundColor: '#fff !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#f94700 !important',
        border: '1px solid #f94700 !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important',
        float: 'right'
    },
    th: {
        fontWeight: 'bolder !important',
        textAlign: 'center !important'
    }
})

const ITEM_HEIGHT = 56;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TicketContainer = () => {

    const classes = useStyles()

    const { tickets, getTickets, deleteTicket, propietarioFilter2, setPropietarioFilter2, tipoClienteFilter, setTipoClienteFilter, desdeFilter, setDesdeFilter, hastaFilter, setHastaFilter, nombresFilter, setNombresFilter, setActiveEditarModal, setTicket, setActiveDetalleModal,  setActivePdfViewer, getDetalleTicket, propietarios, getPropietario, fechaFilter, setFechaFilter, getArticuloPorPropietario, preArticles, setPreArticles, ids, setIds, generarTickets, setCheckServicio, setCheckProrrateo, setTypePdf, buildPdfTicket} = useContext(TicketContext)
    const { propietario, buscarPropietario } = useContext(PropietarioContext)
    const { isLoading} = useContext(GlobalContext)

    const [showGenerar, setShowGenerar] = useState(false)

    const handleChange = (evt) => {
        const { name, value } = evt.target

        if (name === 'nombres') {
            setNombresFilter(value)
        } else if (name === 'tipoClienteFilter') {
            setTipoClienteFilter(value)
        } else if (name === 'propietarioFilter') {
            setPropietarioFilter2(value)
            if(value.length === 1){
                if(fechaFilter) {
                    getArticuloPorPropietario(value[0].split("-")[0],fechaFilter.toJSON().slice(0,10))
                }
            } else {
                setPreArticles([])
                setIds([])
            }
        }
    }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getTickets();
        }
    };

    const showMessage = () => {
        if(propietarioFilter2.length === 1 && fechaFilter && preArticles.length === 0) {
            return true;
        }
        return false;
    }

    const isNotValid = () => {
        if((propietarioFilter2.length === 1 && fechaFilter && ids.length) || (propietarioFilter2.length !== 1 && fechaFilter)) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        setFechaFilter(null)
        buscarPropietario();
        getTickets();
        getPropietario();
    }, [])


    return (
        <div className='administrar-container__item ticketContainer'>
            {
                isLoading && (
                    <div className='progressContainer'>
                        <CircularProgress color="success" variant="indeterminate" value={40} style={{ width: '40px', height: '40px' }} /> 
                    </div>
                )
            }
            <div className='menuArea'>
                <div className='menuHeader'>
                    <h2>Tickets</h2>
                </div>
                {showGenerar ? (
                    <>
                        <div className='menuFooter'>
                            <Button
                                className={classes.button3}
                                onClick={() => {
                                    setShowGenerar(false);
                                }}
                            >
                                <FontAwesomeIcon className='navIcon' icon={faArrowLeft} />
                                Volver
                            </Button>
                        </div>
                        <div className={"menuInputs"}>
                            <CssFormControl fullWidth
                                style={{ width: '100%' }}>
                                <InputLabel id="tipo-cliente-label" size="small"
                                    >{propietarioFilter2.length?'Propietarios':'Todos los propietarios'}</InputLabel>
                                <Select
                                labelId="demo-multiple-chip-label"
                                    label={propietarioFilter2.length?'Propietarios':'Todos los propietarios'}
                                    id="demo-multiple-chip"
                                    variant="outlined"
                                    name='propietarioFilter'
                                    multiple
                                    className={`customSelect ${classes.root}`}
                                    value={propietarioFilter2}
                                    onChange={handleChange}
                                    size="small"
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                          {(selected.map((value) => (
                                            <Chip key={value.split("-")[0]} label={value.split("-")[1]} />
                                          )))}
                                        </Box>
                                      )}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        propietarios.map((item) => (
                                            <MenuItem 
                                            key={item.idPropietario}
                                            value={item.idPropietario+'-'+item.nombres}>
                                                {item.nombres}
                                            </MenuItem>
                                        ))

                                    }
                                </Select>
                            </CssFormControl>
                            <CssFormControl>
                            
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="Fecha fin" format="YYYY-MM-DD" slotProps={{ textField: { size: 'small' } }} value={fechaFilter}
                                        onChange={(newValue) => {
                                            setFechaFilter(newValue)
                                            if(propietarioFilter2.length === 1) {
                                                getArticuloPorPropietario(propietarioFilter2[0].split("-")[0],newValue.toJSON().slice(0,10))
                                            }
                                        }}
                                        />
                                </LocalizationProvider>
                            </CssFormControl>
                            <CssFormControl style={{display:"flex", flexDirection:"row"}}>
                                <FormControlLabel
                                control ={
                                    <Checkbox
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#F94700',
                                            },
                                        }}
                                        onClick={(e) => {
                                            if (e.target.checked) {
                                                setCheckServicio(true)
                                            } else {
                                                setCheckServicio(false)
                                            }
                                        }}
                                    />
                                }
                                label="Incluir servicios"
                                />
                                <FormControlLabel
                                control ={
                                    <Checkbox
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#F94700',
                                            },
                                        }}
                                        onClick={(e) => {
                                            if (e.target.checked) {
                                                setCheckProrrateo(true)
                                            } else {
                                                setCheckProrrateo(false)
                                            }
                                        }}
                                    />
                                }
                                label="Incluir Prorrateos"
                                />
                            </CssFormControl>
                        </div>
                        <hr />

                        {
                            preArticles.length > 0 && (<div className='articulosTicket'>
                                {preArticles.map(articulo => {
                                    return (
                                        <ArticuloCarrito key={articulo.idArticulo} articulo={articulo} />
                                    )
                                })}
                            </div>)
                        }
                        {
                            showMessage() && (
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    <p>No hay artículos para mostrar</p>
                                </div>
                            )
                        }                        
                        <Button
                                type='button'
                                disabled={isNotValid()}
                                className={`buttonG ${classes.button2}`}
                                onClick={() => {
                                    generarTickets();
                                }}
                            >Generar </Button>
                    </>
                ):(
                    <>
                        {propietario !== null ? (
                            <div className={"menuInputs"}>
                                <CssFormControl fullWidth
                                    style={{ marginBottom: '16px !important', width: '100%' }}>
                                    <InputLabel id="tipo-cliente-label" size="small">Tipo de cliente</InputLabel>
                                    <Select
                                        label="Tipo de cliente"
                                        variant="outlined"
                                        name='tipoClienteFilter'
                                        className={`customSelect ${classes.root}`}
                                        value={tipoClienteFilter}
                                        onChange={handleChange}
                                        size="small"
                                    >
                                        <MenuItem value="">Todos los tipos</MenuItem>
                                        <MenuItem value="Persona">Persona</MenuItem>
                                        <MenuItem value="Empresa">Empresa</MenuItem>
                                    </Select>
                                </CssFormControl>

                                <CssFormControl fullWidth
                                    style={{ marginBottom: '16px !important', width: '100%' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker label="Desde" format="YYYY-MM-DD" slotProps={{ textField: { size: 'small' } }} value={dayjs(desdeFilter)}
                                            onChange={(newValue) => {
                                                setDesdeFilter(newValue.toISOString().slice(0,10))
                                            }}
                                            />
                                    </LocalizationProvider>
                                </CssFormControl>

                                <CssTextField fullWidth
                                    label="Nombres"
                                    variant="outlined"
                                    name="nombres"
                                    size="small"
                                    value={nombresFilter}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onBlur={handleChange}
                                />

                                <CssFormControl fullWidth
                                    style={{ marginBottom: '16px !important', width: '100%' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="Hasta" format="YYYY-MM-DD" slotProps={{ textField: { size: 'small' } }} value={dayjs(hastaFilter)}
                                        onChange={(newValue) => {
                                            setHastaFilter(newValue.toISOString().slice(0,10));
                                        }}
                                        />
                                    </LocalizationProvider>
                                </CssFormControl>
                            </div>
                        ) : (
                            <h1>Cargando...</h1>
                        )}
                        <div className='menuFooter'>
                            <Button
                                className={classes.button3}
                                onClick={() => {
                                    setShowGenerar(true);
                                }}
                            >Nuevo</Button>
                            <Button
                                className={classes.button2}
                                onClick={getTickets}
                            >Buscar</Button>
                        </div>
                        <div className='menuContent'>
                            <TableContainer component={Paper} style={{maxHeight:600,minHeight:300,height:'calc(100vh - 300px)'}}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.th}>Ticket</TableCell>
                                            <TableCell className={classes.th}>Mes</TableCell>
                                            <TableCell className={classes.th}>Propietario</TableCell>
                                            <TableCell className={classes.th}>Estado</TableCell>
                                            <TableCell className={classes.th}>Tipo</TableCell>
                                            <TableCell className={classes.th}>Monto</TableCell>
                                            <TableCell className={classes.th}>Nro Comprobante</TableCell>
                                            <TableCell className={classes.th}>PDF</TableCell>
                                            <TableCell className={classes.th}>T. Niubiz</TableCell>
                                            <TableCell className={classes.th}>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tickets.map((row) => (
                                            <TableRow
                                                key={row.idTicket}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{row.label}</TableCell>
                                                <TableCell>{row.periodo}</TableCell>
                                                <TableCell>{row.Nombre + ' ' + row.Apellido}</TableCell>
                                                <TableCell>{row.estado}</TableCell>
                                                <TableCell>{row.tipoPago}</TableCell>
                                                <TableCell>{row.monto}</TableCell>
                                                <TableCell>{row.numero}</TableCell>
                                                <TableCell>{row.filename}</TableCell>
                                                <TableCell>{row.niubiz}</TableCell>
                                                <TableCell>
                                                    <div style={{ display: 'flex' }}>
                                                        <FontAwesomeIcon className='menuIcon' icon={faPen} onClick={() => {
                                                                window.scrollTo(0, 0)
                                                                document.body.style.overflowY = "hidden"
                                                                setActiveEditarModal(true)
                                                                setTicket(Object.assign({}, row))
                                                            }
                                                        } />
                                                        { row.estado === 'Pendiente' && (<FontAwesomeIcon className='menuIcon' icon={faTrashCan} onClick={() => {
                                                            Swal.fire({
                                                                title: 'Estás seguro?',
                                                                text: "No podrás revertir esta acción!",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'Sí',
                                                                cancelButtonText: 'No'
                                                                }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    deleteTicket(row.idTicket)
                                                                }
                                                                })
                                                        }
                                                        } />)}
                                                        <FontAwesomeIcon className='menuIcon' icon={faRotate} onClick={() => {
                                                            Swal.fire({
                                                                title: 'Estás seguro?',
                                                                text: "Se volverá a generar el pdf!",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'Sí',
                                                                cancelButtonText: 'No'
                                                                }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    buildPdfTicket(row.idTicket)
                                                                }
                                                                })
                                                        }
                                                        }/>
                                                        <FontAwesomeIcon className='menuIcon' icon={faList} onClick={() => {
                                                            window.scrollTo(0, 0)
                                                            document.body.style.overflowY = "hidden"
                                                            setActiveDetalleModal(true)
                                                            getDetalleTicket(Object.assign({}, row))
                                                        }
                                                        } />
                                                        <FontAwesomeIcon className='menuIcon' icon={faReceipt} onClick={() => {
                                                            window.scrollTo(0, 0)
                                                            setTicket(Object.assign({}, row))
                                                            document.body.style.overflowY = "hidden"
                                                            setTypePdf('comprobante')
                                                            setActivePdfViewer(true)
                                                        }
                                                        } />
                                                        <FontAwesomeIcon className='menuIcon' icon={faEye} onClick={() => {
                                                            window.scrollTo(0, 0)
                                                            setTicket(Object.assign({}, row))
                                                            document.body.style.overflowY = "hidden"
                                                            setTypePdf('ticket')
                                                            setActivePdfViewer(true)
                                                        }
                                                        } />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                )}
                
            </div>
        </div>
    )
}

export default TicketContainer