import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { TextField, Button, CircularProgress } from '@mui/material'
import { withStyles, makeStyles } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import './LoginContainer.css'
import '../InitialScreen.css'
import AuthContext from '../../../context/AuthContext'

const CssTextField = withStyles({
  root: {
    '& p': {
      textAlign: 'left',
      fontSize: '12px',
      color: '#ff5252 !important'
    },
    '& label': {
      fontSize: '16px'
    },
    '& label.Mui-focused': {
      color: '#ff5252 !important',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #000 !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: '1px solid #ff5252 !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black'
      },
      '&:hover fieldset': {
        borderColor: 'black'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ff5252 !important'
      },
    },
  },
})(TextField);

const useStyles = makeStyles({
  button: {
    backgroundColor: '#f94700 !important',
    textTransform: 'none !important',
    fontWeight: 'bold !important',
    width: '240px !important'
  }
})

const LoginContainer = () => {
  const classes = useStyles()

  const { email, emailAction, handleChange, handleKeyDown, credentialsError, error, password, passwordAction, verifyUser, isLoading } = useContext(AuthContext)

  return (
    <div className='loginContainer initialContainer'>
      <div className='formContainer'>
        <div className='logo' style={{ padding: '30px' }}>
          <img src="https://storange-images.s3.amazonaws.com/img/storangelogo.png" alt="storange logo" />
        </div>
        <div className="title">
          <h1>Panel de Administración</h1>
          <p>Escribe tus credenciales</p>
        </div>
        <div className={credentialsError ? 'alert' : 'alert visible'}>
          <div className='border'></div>
          <div className='icon'>
            <FontAwesomeIcon className='alertIcon' icon={faTriangleExclamation} />
          </div>
          <div className='text'>{error}</div>
        </div>
        <div className="form">
          <CssTextField
            type={'email'}
            label="Email"
            variant="standard"
            name="email"
            onChange={handleChange}
            onBlur={handleChange}
            onKeyDown={handleKeyDown}
            error={email === '' && emailAction}
            helperText={email === '' && emailAction ? 'Email requerido' : ' '}
            value={email}
            style={{ marginBottom: '16px', width: '100%' }}
          />
          <CssTextField
            type={'password'}
            label="Contraseña"
            variant="standard"
            name="password"
            onChange={handleChange}
            onBlur={handleChange}
            onKeyDown={handleKeyDown}
            error={password === '' && passwordAction}
            helperText={password === '' && passwordAction ? 'Contraseña requerida' : ' '}
            value={password}
            style={{ marginBottom: '16px', width: '100%' }}
          />
          <Button
            className={classes.button}
            onClick={verifyUser}
            variant='contained'>
            {isLoading ? <CircularProgress style={{width: '20px', height: '20px', color:'#fff'}}/> : 'Iniciar sesión'}
          </Button>
        </div>
        <div className='options'>
          <Link to={'/recovery'}>Recuperar contraseña</Link>
          <div>|</div>
          <Link to={'/register'}>Regístrate</Link>
        </div>
      </div>
    </div>
  )
}

export default LoginContainer