import React from 'react'
import { faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalStateContext'
import './DetalleModal.css'
import {
    Button, TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material'

import { makeStyles, withStyles } from '@mui/styles'
import TicketContext from '../../context/TicketContext'

const CssTextField = withStyles({
    root: {
        '&': {
            margin: '10px 0 10px !important'
        },
        '& p': {
            textAlign: 'left',
            fontSize: '12px',
            color: '#F94700 !important'
        },
        '& label': {
            fontSize: '16px'
        },
        '& label.Mui-focused': {
            color: '#F94700 !important',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #000 !important'
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #F94700 !important',
        },
        '& .MuiSelect-select': {
            paddingLeft: '0 !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black'
            },
            '&:hover fieldset': {
                borderColor: 'black'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#F94700 !important'
            },
            '&.Mui-disabled': {
                color: 'red !important'
            }
        },
    },
})(TextField);

const useStyles = makeStyles({
    button: {
        backgroundColor: '#f94700 !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#fff !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important'
    },
    th: {
        fontWeight: 'bolder !important',
        textAlign: 'center !important'
    },
    td: {
        textAlign: 'center !important'
    }
})


const DetalleModal = () => {

    const classes = useStyles()
    const { activeDetalleModal, setActiveDetalleModal, ticket, setTicketDefault, setActiveDetalleArticuloModal, detalle, getDetalleTicketArticulos } = useContext(TicketContext)

    return (
        <div className={activeDetalleModal ? 'detalleModal activeModal' : 'detalleModal'}>
            <div className='modalContainer'>
                <div className='buttonContainer'>
                    <div></div>
                    <h2>Detalle Ticket</h2>
                    <FontAwesomeIcon
                        className='closeModalIcon'
                        onClick={() => {
                            document.body.style.overflowY = "visible"
                            setActiveDetalleModal(false)
                            setTicketDefault()
                        }}
                        icon={faXmark} />
                </div>
                <div className='detalleContainer'>
                    <CssTextField fullWidth
                        label="Id Ticket"
                        variant="outlined"
                        name="id"
                        size="small"
                        value={ticket.label}
                        disabled
                    />
                    <CssTextField fullWidth
                        label="Periodo"
                        variant="outlined"
                        name="periodo"
                        size="small"
                        value={ticket.periodo}
                        disabled
                    />
                    <CssTextField fullWidth
                        label="Propietario"
                        variant="outlined"
                        name="propietario"
                        size="small"
                        value={ticket.propietario}
                        disabled
                    />
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableBody>
                                    {detalle.filter(item => item.tipo == 'Helper').map((row) => (
                                        <TableRow
                                            key={row.idDetalleTicket}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{row.concepto}
                                            { ['01', '02', '03'].includes(row.tipoConcepto) && (<FontAwesomeIcon className='linkIcon' icon={faArrowRight} onClick={() => {
                                                document.body.style.overflowY = "visible"
                                                setActiveDetalleArticuloModal(true)
                                                getDetalleTicketArticulos(row.idDetalleTicket)
                                            }} />)}</TableCell>
                                            <TableCell  className={classes.td}>{row.monto} m<sup>3</sup> </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow
                                            key={'unique'+ticket.idTicket}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell className={classes.th}></TableCell>
                                        <TableCell  className={classes.td}></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.th}>Concepto</TableCell>
                                        <TableCell className={classes.th}>Monto</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {detalle.filter(item => item.tipo != 'Helper').map((row) => (
                                        <TableRow
                                            key={row.idDetalleTicket}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{row.concepto}</TableCell>
                                            <TableCell  className={classes.td}>S/. {row.monto}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow
                                            key={'unique'+ticket.idTicket}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell className={classes.th}>Monto Final</TableCell>
                                        <TableCell  className={classes.td}>S/. {ticket.monto}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetalleModal