import React from 'react'
import Layout from '../components/Layout'
import LoginView from '../views/LoginView'
import RecoveryView from '../views/RecoveryView'
import AdministrarView from '../views/AdministrarView'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ScrollToTop from '../tools/ScrollToTop'
import PerfilView from '../views/PerfilView'

const Rutas = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
            <Route path='/' element={<LoginView/>}/>
            <Route path='/recovery' element={<RecoveryView/>}/>
            <Route path='/admin' element={<Layout/>}>
              <Route index element={<AdministrarView/>}/>
            </Route>
            <Route path='/perfil' element={<Layout/>}>
              <Route index element={<PerfilView/>}/>
            </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default Rutas